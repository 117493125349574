<template>
  <div class="top-tournament" data-t="top-tournament">
    <NuxtI18nLink
      class="card"
      :to="tournament.link"
      data-t="top-tournament-link"
    />
    <div class="label" data-t="top-tournament-label">
      {{ tournament.name }}
    </div>
  </div>
</template>

<script setup lang="ts">
import type { TopTournament } from '../../composables/useTopTournaments'

const props = defineProps<{
  tournament: TopTournament
}>()

const image = computed(() => `url("${props.tournament.backgroundImage}")`)
</script>

<style scoped>
.top-tournament {
  width: 80px;
}

.card {
  display: block;

  width: 80px;
  height: 80px;

  background-color: var(--background-secondary);
  background-image: v-bind(image);
  background-size: cover;
  border-radius: var(--border-radius-150);

  transition: transform 200ms;

  &:hover {
    transform: translateY(-6px);
  }
}

.label {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;

  max-width: 400px;
  margin-top: var(--spacing-075);

  font: var(--desktop-text-xs-medium);
  color: var(--text-primary);
  text-align: center;
  text-overflow: ellipsis;

  -webkit-line-clamp: 2;
}
</style>
