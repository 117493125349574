<template>
  <StSlider
    :items-gap="8"
    :arrow-offset-vertical="14"
    :scrollable="!skeletonsCount"
    data-t="top-tournaments"
  >
    <StSliderItem v-if="isLiveVisible">
      <LiveNowLinkBanner :counter="liveCounter" size="m" />
    </StSliderItem>
    <StSliderItem v-for="tournament in topTournaments" :key="tournament.id">
      <TopTournamentsItem :tournament="tournament" />
    </StSliderItem>
    <div v-for="slide in skeletonsCount" :key="slide" class="skeleton-slide" />
  </StSlider>
</template>

<script setup lang="ts">
import TopTournamentsItem from './TopTournamentsItem.vue'
import LiveNowLinkBanner from '../LiveNowLink/LiveNowLinkBanner.vue'
import { useTopTournaments } from '../../composables'

interface Props {
  isLiveVisible?: boolean
}

defineProps<Props>()

const { topTournaments } = useTopTournaments()

const MIN_SLIDER_ITEMS_COUNT = 15
const skeletonsCount = computed(() => {
  const slidesCount = MIN_SLIDER_ITEMS_COUNT - topTournaments.value.length

  return slidesCount > 0 ? slidesCount : 0
})

const live = useLiveEvents()
const liveCounter = computed(() => live.value.length)
</script>

<style scoped>
:deep(.st-slider-content) {
  display: flex;
  /* stylelint-disable */
  padding-top: 6px;
  margin-top: -6px;
  /* stylelint-enable */
}

.skeleton-slide {
  pointer-events: none;

  width: 80px;
  height: 80px;

  opacity: 0.4;
  background: var(--background-primary);
  border-radius: var(--border-radius-150);
}
</style>
